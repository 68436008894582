import {
  Box,
  Divider,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react"
import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"

interface UniformBoxProps {
  children: React.ReactNode
}

function UniformBox({ children }: UniformBoxProps): JSX.Element {
  return (
    <Box maxW="95vw" mx="auto" py="24px" w="800px">
      {children}
    </Box>
  )
}

function Privacy({ location }: PageProps): JSX.Element {
  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Sundog Homes places a high value on personal privacy. Read our privacy policy to learn more.",
          image: `${location.origin}/sundog-homes-logo.png`,
          imageAlt: `The Sundog Homes logo`,
        }}
        title="Privacy Policy | Sundog Homes"
        url={location.href}
      />
      <VStack>
        <UniformBox>
          <Heading fontSize="5xl" mb={6} mt={18}>
            Privacy Policy
          </Heading>
          <Text mb={6} mt={2}>
            Last updated: October 1, 2021
          </Text>
          <Text my={2}>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </Text>
          <Text my={2}>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </Text>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            Interpretation and Definitions
          </Heading>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Interpretation
          </Heading>
          <Text my={2}>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions.
          </Text>
          <Text my={2}>
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </Text>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Definitions
          </Heading>
          <Text my={2}>For the purposes of this Privacy Policy:</Text>
          <UnorderedList>
            <ListItem>
              <Text my={2}>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </Text>
            </ListItem>
            <ListItem>
              <Text my={2}>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to Sundog Homes,
                Inc., 702 W. Main St., Sylva, NC 28779.
              </Text>
            </ListItem>
            <ListItem>
              <strong>Affiliate</strong> means an entity that controls, is
              controlled by or is under common control with a party, where
              "control" means ownership of 50% or more of the shares, equity
              interest or other securities entitled to vote for election of
              directors or other managing authority.
            </ListItem>
            <ListItem>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </ListItem>
            <ListItem>
              <strong>Website</strong> refers to Sundog Homes, accessible from
              https://www.sundoghomes.com
            </ListItem>{" "}
            <ListItem>
              <strong>Service</strong> refers to the Website.
            </ListItem>
            <ListItem>
              <strong>Country</strong> refers to: North Carolina, United States
            </ListItem>
            <ListItem>
              <Text my={2}>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </Text>
            </ListItem>
            <ListItem>
              <strong>Third-party Social Media Service</strong> refers to any
              website or any social network website through which a User can log
              in or create an account to use the Service.
            </ListItem>
            <ListItem>
              <Text my={2}>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </Text>
            </ListItem>
            <ListItem>
              <strong>Cookies</strong> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many uses.
            </ListItem>{" "}
            <ListItem>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </ListItem>
            <ListItem>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </ListItem>
          </UnorderedList>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            Collecting and Using Your Personal Data
          </Heading>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Types of Data Collected
          </Heading>
          <Heading as="h4" fontSize="xl" mt={4}>
            Personal Data
          </Heading>
          <Text my={2}>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </Text>
          <UnorderedList>
            <ListItem>Email address</ListItem>{" "}
            <ListItem>First name and last name</ListItem>{" "}
            <ListItem>Phone number</ListItem>{" "}
            <ListItem>Address, State, Province, ZIP/Postal code, City</ListItem>{" "}
            <ListItem>Usage Data</ListItem>
          </UnorderedList>
          <Heading as="h4" fontSize="xl" mt={4}>
            Usage Data
          </Heading>
          <Text my={2}>
            Usage Data is collected automatically when using the Service.
          </Text>
          <Text my={2}>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </Text>
          <Text my={2}>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </Text>
          <Text my={2}>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </Text>
          <Heading as="h4" fontSize="xl" mt={4}>
            Tracking Technologies and Cookies
          </Heading>
          <Text my={2}>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service.
          </Text>
          <Text my={2}>
            You can instruct Your browser to refuse all Cookies or to indicate
            when a Cookie is being sent. However, if You do not accept Cookies,
            You may not be able to use some parts of our Service.
          </Text>
          <Text my={2}>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close your
            web browser. Learn more about cookies in the{" "}
            <a href="https://www.privacypolicies.com/blog/cookies/">
              "What Are Cookies"
            </a>{" "}
            article.
          </Text>
          <Text my={2}>
            We use both session and persistent Cookies for the purposes set out
            below:
          </Text>
          <UnorderedList>
            <ListItem>
              <Text my={2}>
                <strong>Necessary / Essential Cookies</strong>
              </Text>
              <Text my={2}>Type: Session Cookies</Text>
              <Text my={2}>Administered by: Us</Text>
              <Text my={2}>
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </Text>
            </ListItem>
            <ListItem>
              <Text my={2}>
                <strong>Cookies Policy / Notice Acceptance Cookies</strong>
              </Text>
              <Text my={2}>Type: Persistent Cookies</Text>
              <Text my={2}>Administered by: Us</Text>
              <Text my={2}>
                Purpose: These Cookies identify if users have accepted the use
                of cookies on the Website.
              </Text>
            </ListItem>
            <ListItem>
              <Text my={2}>
                <strong>Functionality Cookies</strong>
              </Text>
              <Text my={2}>Type: Persistent Cookies</Text>
              <Text my={2}>Administered by: Us</Text>
              <Text my={2}>
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </Text>
            </ListItem>
            <ListItem>
              <Text my={2}>
                <strong>Tracking and Performance Cookies</strong>
              </Text>
              <Text my={2}>Type: Persistent Cookies</Text>
              <Text my={2}>Administered by: Third-Parties</Text>
              <Text my={2}>
                Purpose: These Cookies are used to track information about
                traffic to the Website and how users use the Website. The
                information gathered via these Cookies may directly or
                indirectly identify you as an individual visitor. This is
                because the information collected is typically linked to a
                pseudonymous identifier associated with the device you use to
                access the Website. We may also use these Cookies to test new
                pages, features or new functionality of the Website to see how
                our users react to them.
              </Text>
            </ListItem>
          </UnorderedList>
          <Text my={2}>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy.
          </Text>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Use of Your Personal Data
          </Heading>
          <Text my={2}>
            The Company may use Personal Data for the following purposes:
          </Text>
          <UnorderedList>
            <ListItem>
              <strong>To provide and maintain our Service</strong>, including to
              monitor the usage of our Service.
            </ListItem>
            <ListItem>
              <strong>To manage Your Account:</strong> to manage Your
              registration as a user of the Service. The Personal Data You
              provide can give You access to different functionalities of the
              Service that are available to You as a registered user.
            </ListItem>
            <ListItem>
              <strong>For the performance of a contract:</strong> the
              development, compliance and undertaking of the purchase contract
              for the products, items or services You have purchased or of any
              other contract with Us through the Service.
            </ListItem>
            <ListItem>
              <strong>To contact You:</strong> To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic
              communication, such as a mobile application's push notifications
              regarding updates or informative communications related to the
              functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their
              implementation.
            </ListItem>
            <ListItem>
              <strong>To provide You</strong> with news, special offers and
              general information about other goods, services and events which
              we offer that are similar to those that you have already purchased
              or enquired about unless You have opted not to receive such
              information.
            </ListItem>
            <ListItem>
              <strong>To manage Your requests:</strong> To attend and manage
              Your requests to Us.
            </ListItem>
          </UnorderedList>
          <Text my={2}>
            We may share your personal information in the following situations:
          </Text>
          <UnorderedList>
            <ListItem>
              <strong>With Service Providers:</strong> We may share Your
              personal information with Service Providers to monitor and analyze
              the use of our Service, to advertise on third party websites to
              You after You visited our Service, to contact You.
            </ListItem>
            <ListItem>
              <strong>For Business transfers:</strong> We may share or transfer
              Your personal information in connection with, or during
              negotiations of, any merger, sale of Company assets, financing, or
              acquisition of all or a portion of our business to another
              company.
            </ListItem>
            <ListItem>
              <strong>With Affiliates:</strong> We may share Your information
              with Our affiliates, in which case we will require those
              affiliates to honor this Privacy Policy. Affiliates include Our
              parent company and any other subsidiaries, joint venture partners
              or other companies that We control or that are under common
              control with Us.
            </ListItem>
            <ListItem>
              <strong>With Business partners:</strong> We may share Your
              information with Our business partners to offer You certain
              products, services or promotions.
            </ListItem>
            <ListItem>
              <strong>With other users:</strong> when You share personal
              information or otherwise interact in the public areas with other
              users, such information may be viewed by all users and may be
              publicly distributed outside. If You interact with other users or
              register through a Third-Party Social Media Service, Your contacts
              on the Third-Party Social Media Service may see Your name,
              profile, pictures and description of Your activity. Similarly,
              other users will be able to view descriptions of Your activity,
              communicate with You and view Your profile.
            </ListItem>
          </UnorderedList>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Retention of Your Personal Data
          </Heading>
          <Text my={2}>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </Text>
          <Text my={2}>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </Text>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Transfer of Your Personal Data
          </Heading>
          <Text my={2}>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </Text>
          <Text my={2}>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </Text>
          <Text my={2}>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </Text>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Disclosure of Your Personal Data
          </Heading>
          <Heading as="h4" fontSize="xl" mt={4}>
            Business Transactions
          </Heading>
          <Text my={2}>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </Text>
          <Heading as="h4" fontSize="xl" mt={4}>
            Law enforcement
          </Heading>
          <Text my={2}>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </Text>
          <Heading as="h4" fontSize="xl" mt={4}>
            Other legal requirements
          </Heading>
          <Text my={2}>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </Text>
          <UnorderedList>
            <ListItem>Comply with a legal obligation</ListItem>
            <ListItem>
              Protect and defend the rights or property of the Company
            </ListItem>
            <ListItem>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </ListItem>
            <ListItem>
              Protect the personal safety of Users of the Service or the public
            </ListItem>
            <ListItem>Protect against legal liability</ListItem>
          </UnorderedList>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Security of Your Personal Data
          </Heading>
          <Text my={2}>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </Text>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            Detailed Information on the Processing of Your Personal Data
          </Heading>
          <Text my={2}>
            Service Providers have access to Your Personal Data only to perform
            their tasks on Our behalf and are obligated not to disclose or use
            it for any other purpose.
          </Text>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Analytics
          </Heading>
          <Text my={2}>
            We may use third-party Service providers to monitor and analyze the
            use of our Service.
          </Text>
          <UnorderedList>
            <ListItem>
              <Text my={2}>
                <strong>Google Analytics</strong>
              </Text>
              <Text my={2}>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network.
              </Text>
              <Text my={2}>
                You can opt-out of having made your activity on the Service
                available to Google Analytics by installing the Google Analytics
                opt-out browser add-on. The add-on prevents the Google Analytics
                JavaScript (ga.js, analytics.js and dc.js) from sharing
                information with Google Analytics about visits activity.
              </Text>{" "}
              <Text my={2}>
                For more information on the privacy practices of Google, please
                visit the Google Privacy &amp; Terms web page:{" "}
                <a href="https://policies.google.com/privacy?hl=en">
                  https://policies.google.com/privacy?hl=en
                </a>
              </Text>
            </ListItem>
          </UnorderedList>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Email Marketing
          </Heading>
          <Text my={2}>
            We may use Your Personal Data to contact You with newsletters,
            marketing or promotional materials and other information that may be
            of interest to You. You may opt-out of receiving any, or all, of
            these communications from Us by following the unsubscribe link or
            instructions provided in any email We send or by contacting Us.
          </Text>
          <Text my={2}>
            We may use Email Marketing Service Providers to manage and send
            emails to You.
          </Text>
          <Heading as="h3" fontSize="2xl" mt={6}>
            Behavioral Remarketing
          </Heading>
          <Text my={2}>
            The Company uses remarketing services to advertise on third party
            websites to You after You visited our Service. We and Our
            third-party vendors use cookies to inform, optimize and serve ads
            based on Your past visits to our Service.
          </Text>
          <UnorderedList>
            <ListItem>
              <Text my={2}>
                <strong>Google Ads (AdWords)</strong>
              </Text>
              <Text my={2}>
                Google Ads (AdWords) remarketing service is provided by Google
                Inc.
              </Text>
              <Text my={2}>
                You can opt-out of Google Analytics for Display Advertising and
                customise the Google Display Network ads by visiting the Google
                Ads Settings page:{" "}
                <a href="http://www.google.com/settings/ads">
                  http://www.google.com/settings/ads
                </a>
              </Text>
              <Text my={2}>
                Google also recommends installing the Google Analytics Opt-out
                Browser Add-on -{" "}
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>{" "}
                - for your web browser. Google Analytics Opt-out Browser Add-on
                provides visitors with the ability to prevent their data from
                being collected and used by Google Analytics.
              </Text>
              <Text my={2}>
                For more information on the privacy practices of Google, please
                visit the Google Privacy &amp; Terms web page:{" "}
                <a href="https://policies.google.com/privacy?hl=en">
                  https://policies.google.com/privacy?hl=en
                </a>
              </Text>
            </ListItem>
            <ListItem>
              <Text my={2}>
                <strong>Bing Ads Remarketing</strong>
              </Text>
              <Text my={2}>
                Bing Ads remarketing service is provided by Microsoft Inc.
              </Text>
              <Text my={2}>
                You can opt-out of Bing Ads interest-based ads by following
                their instructions:{" "}
                <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                  https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                </a>
              </Text>
              <Text my={2}>
                You can learn more about the privacy practices and policies of
                Microsoft by visiting their Privacy Policy page:{" "}
                <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">
                  https://privacy.microsoft.com/en-us/PrivacyStatement
                </a>
              </Text>
            </ListItem>
            <ListItem>
              <Text my={2}>
                <strong>Facebook</strong>
              </Text>
              <Text my={2}>
                Facebook remarketing service is provided by Facebook Inc.
              </Text>
              <Text my={2}>
                You can learn more about interest-based advertising from
                Facebook by visiting this page:{" "}
                <a href="https://www.facebook.com/help/164968693837950">
                  https://www.facebook.com/help/164968693837950
                </a>
              </Text>
              <Text my={2}>
                To opt-out from Facebook's interest-based ads, follow these
                instructions from Facebook:{" "}
                <a href="https://www.facebook.com/help/568137493302217">
                  https://www.facebook.com/help/568137493302217
                </a>
              </Text>
              <Text my={2}></Text>
              <Text my={2}>
                Facebook adheres to the Self-Regulatory Principles for Online
                Behavioural Advertising established by the Digital Advertising
                Alliance. You can also opt-out from Facebook and other
                participating companies through the Digital Advertising Alliance
                in the USA{" "}
                <a href="http://www.aboutads.info/choices/">
                  http://www.aboutads.info/choices/
                </a>
                , the Digital Advertising Alliance of Canada in Canada{" "}
                <a href="http://youradchoices.ca/">http://youradchoices.ca/</a>{" "}
                or the European Interactive Digital Advertising Alliance in
                Europe{" "}
                <a href="http://www.youronlinechoices.eu/">
                  http://www.youronlinechoices.eu/
                </a>
                , or opt-out using your mobile device settings.
              </Text>
              <Text my={2}>
                For more information on the privacy practices of Facebook,
                please visit Facebook's Data Policy:{" "}
                <a href="https://www.facebook.com/privacy/explanation">
                  https://www.facebook.com/privacy/explanation
                </a>
              </Text>
            </ListItem>
          </UnorderedList>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            Children's Privacy
          </Heading>
          <Text my={2}>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </Text>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            Your California Privacy Rights (California's Shine the Light law)
          </Heading>
          <Text my={2}>
            Under California Civil Code Section 1798 (California's Shine the
            Light law), California residents with an established business
            relationship with us can request information once a year about
            sharing their Personal Data with third parties for the third
            parties' direct marketing purposes.
          </Text>
          <Text my={2}>
            If you'd like to request more information under the California Shine
            the Light law, You can contact Us using the contact information
            provided below.
          </Text>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            California Privacy Rights for Minor Users (California Business and
            Professions Code Section 22581)
          </Heading>
          <Text my={2}>
            California Business and Professions Code section 22581 allow
            California residents under the age of 18 who are registered users of
            online sites, services or applications to request and obtain removal
            of content or information they have publicly posted.
          </Text>
          <Text my={2}>
            To request removal of such data, and if you are a California
            resident, You can contact Us using the contact information provided
            below, and include the email address associated with Your account.
          </Text>
          <Text my={2}>
            Be aware that Your request does not guarantee complete or
            comprehensive removal of content or information posted online and
            that the law may not permit or require removal in certain
            circumstances.
          </Text>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack>
        <UniformBox>
          <Heading as="h2" my={6}>
            Links to Other Websites
          </Heading>
          <Text my={2}>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </Text>
          <Text my={2}>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </Text>
          <Heading as="h2" my={6}>
            Changes to this Privacy Policy
          </Heading>
          <Text my={2}>
            We may update our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </Text>
          <Text my={2}>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </Text>
          <Text my={2}>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </Text>
        </UniformBox>
      </VStack>
      <Divider maxW="95vw" mx="auto" my={6} w="800px" />
      <VStack mb={24}>
        <UniformBox>
          <Heading as="h2" my={6}>
            Contact Us
          </Heading>
          <Text my={2}>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </Text>
          <UnorderedList>
            <ListItem>
              By email:{" "}
              <Link href={`mailto:contact@sundoghomes.com`}>
                contact@sundoghomes.com
              </Link>
            </ListItem>
          </UnorderedList>
        </UniformBox>
      </VStack>
    </Layout>
  )
}

export default Privacy
